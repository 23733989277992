.postReviewTextField .MuiInputBase-input {
  color: #000;
  background-color: #f3f4f4;
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  padding: 20px 10px 0 15px;
  margin-top: 30px;
  height: 88px;
  width: 100%;
  border-radius: 8px;
}
.postReviewTextField .MuiInput-underline::before {
  border-bottom: 0px solid #e9e9e9;
}
.postReviewTextField .MuiInput-underline::after {
  border-bottom: 0px solid #e9e9e9;
}

.postReviewTextField .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: 0px solid #e9e9e9;
}
