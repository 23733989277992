@font-face {
  font-family: Reef;
  src: url("./assets/font/Reef.otf") format("opentype");
}

body {
  margin: 0;
  font-family: Reef;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.add-to-home-banner{
  z-index: 9999;
}
