.feedSearchField .MuiInputBase-root {
  background-color: #dce8f2;
  color: rgba(9, 169, 187, 0.25);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 10px 10px 10px;
  margin-top: 35px;
  margin-bottom: 45px;
  height: 58px;
  width: 100%;
  border-radius: 10px;
}
.MuiBadge-colorPrimary {
  background-color: #14d9d9;
}

.feedSearchField .MuiInput-underline:before {
  border: 0px solid #dce8f2;
}
.feedSearchField .MuiInput-underline::after {
  border: 0px solid #dce8f2;
}
.feedSearchField .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: 0px solid #dce8f2;
}

.webFeedTabsContainer {
  background: #ffffff;
  box-shadow: none;
  margin-left: -35px;
}

.webFeedTabsContainer .MuiTabs-indicator {
  background: #09a9bb;
  border-radius: 12px;
  width: 47px !important;
  border-radius: 12px;
  margin-left: 57px;
  height: 3px;
}

.tabTitleWebFeed {
  color: #cacaca;
  text-shadow: 0px 2px 4px rgba(5, 85, 94, 0.31);
  & * {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: none;
  }
}
.tabTitleWebFeed .Mui-selected {
  color: #09a9bb;
  text-shadow: 0px 2px 4px rgba(5, 85, 94, 0.31);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
