.packagesTextFields .MuiInputBase-input {
    color: #f79d03;
    background-color: #e9e9e9;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    padding-left: 25px;
    margin-top: 25px;
    height: 55px;
    border-radius: 5px;
    
}
.packagesTextFields  .MuiInput-underline::before{
    border-bottom: 1px solid #e9e9e9;
}

.courseDescriptionTextFields .MuiInputBase-input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #505050;
    
}
.courseDescriptionNameField .MuiInputBase-input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #505050;
    text-align:"center"
    
}

.courseDescriptionRemarksField .MuiInputBase-multiline{
  border: 1px solid #FAAC02;
  padding: 10px 10px ;
  border-radius: 10px;
}
.courseDescriptionTextFields .MuiInputLabel-root{
    font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;

color: #505050;

opacity: 0.78;
&::after{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    
    color: #505050;
    
    opacity: 0.78;
}
}
.courseDescriptionNameField .MuiInputLabel-root{
    font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;

color: #505050;

opacity: 0.78;
&::after{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    
    color: #505050;
    
    opacity: 0.78;
}
}

.courseDescriptionRemarksField .MuiInputLabel-root{
    font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;

color: #505050;

opacity: 0.78;
&::after{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    
    color: #505050;
    
    opacity: 0.78;
}
}

 .courseDescriptionRemarksField .MuiInput-underline:before{
    border-bottom: 0px solid #FAAC02;
    border-top: none;
    

}
 .courseDescriptionRemarksField .MuiInput-underline::after{
    border-bottom: 0px solid #FAAC02;
    
}
 .courseDescriptionRemarksField .MuiInput-underline:hover:not(.Mui-disabled)::before{
     border-bottom: 0px solid #FAAC02;
}
 .courseDescriptionNameField .MuiInput-underline:before{
    border-bottom: 1px solid rgba(168, 182, 200, 0.3);
    border-top: none;
    

}
 .courseDescriptionNameField .MuiInput-underline::after{
    border-bottom: 1px solid rgba(168, 182, 200, 0.3);
    
}
 .courseDescriptionNameField .MuiInput-underline:hover:not(.Mui-disabled)::before{
     border-bottom: 1px solid rgba(168, 182, 200, 0.3);
}
 .courseDescriptionTextFields .MuiInput-underline:before{
    border-bottom: 1px solid #FAAC02;
    border-top: none;

}
 .courseDescriptionTextFields .MuiInput-underline::after{
    border-bottom: 1px solid #FAAC02;

}
 .courseDescriptionTextFields .MuiInput-underline:hover:not(.Mui-disabled)::before{
     border-bottom: 1px solid #FAAC02;
}

.modalBtn{
    background: linear-gradient(#FAA902 1%,#FAA902 100%);
    height: 73px;
    width: 100%;
    margin-top: 8%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color:#FFFFFF;
 
}

.CircularProgressbar {
    width: "40%" !important;
}

