.viewCourseTabs {
  background: #ffffff;
  margin-top: 15px;
  box-shadow: none;
}
.viewCourseTabs .MuiTabs-indicator {
  background-color: #15aebf;
  color: #15aebf;
  border-radius: 16px;
}
.tabTitle {
  font-family: Reef;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 1 !important;
  & * {
    text-transform: none;
  }
}
.tabTitle .Mui-selected {
  color: #15aebf !important;
}
.MuiTab-textColorInherit {
  color: #000000 !important;
  opacity: 1 !important;
}

.MuiTab-textColorInherit.Mui-selected {
  color: #15aebf !important;
}
