.name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  padding-top: 30px;
  color: #ffffff;
  text-align: left;
}
.username {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  text-align: left;
  color: #ffffff;
}
.editInfo {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #6e6e6e;
  text-align: center;
  justify-content: right;
  margin-top: 100px;
}
.userPhotoWeb {
  height: 70px;
  width: 70px;
  padding-top: -40px;
}
.counts {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #09a9bb;
}
.countsType {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #09a9bb;
  opacity: 0.65;
}
.hrLine {
  width: 345px;
  border: 1px solid #ededed;
}
.infoTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #3c4a6b;
  padding-bottom: 10px;
  margin-top: 50px;
}
.infoSubTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 8px;
  color: #7b92a6;
  opacity: 0.78;
}
.description {
  width: 345px;
  height: 150px;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 10px;
}
.edit {
  width: 40px;
  height: 40px;

  display: "flex";
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  margin-top: 20%;
  margin-left: 80%;
}

.profile_image {
  margin-top: 10%;
  cursor: pointer;
}

.profile_image input[type="file"] {
  display: none;
}

.analyst-image {
  box-shadow: 0 2px 6px 0;
}
