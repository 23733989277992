.homeSearchField .MuiInputBase-root {
  background-color: #dce8f2;
  color:  #A9A9A9;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 10px 10px 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  height: 58px;
  width: 100%;
  border-radius: 10px;
}
.MuiBadge-colorPrimary{
    background-color: #14d9d9;
  }

.homeSearchField .MuiInput-underline:before {
  border: 0px solid #dce8f2;
}
.homeSearchField .MuiInput-underline::after {
  border: 0px solid #dce8f2;
}
.homeSearchField .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: 0px solid #dce8f2;
}
