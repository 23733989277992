

.sidebar {
     & .MuiDrawer-paperAnchorDockedLeft {
          border-right: none;
          box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.05);
          border-radius: 11px;
     }
    
}

