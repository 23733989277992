.edit {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #6e6e6e;

  text-align: center;
  margin: auto;
  float: "right";
  margin-top: 20%;
  margin-left: 80%;
}

.profile_image {
  cursor: pointer;
}

.profile_image input[type="file"] {
  display: none;
}

.backArrowContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 16px;
  padding-top: 20px;
  // padding-bottom: 10px;
  background: transparent !important;
}
