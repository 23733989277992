
.selectCategory {
    background: #f59604;
    border-radius: 82px;
    height: 41px;
    width: 156px;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    padding-right: 10px;
    &.MuiInput-underline::before {
        border-bottom: none;
    };
    &.MuiInput-underline:after {
        border-bottom: none;
    };
    &.MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
    };
    
}
.selectCategory .MuiSelect-icon{
    color: #ffffff;
    padding-right: 10px;
} 