.tab .MuiTabs-indicator {
    background-color: #387f4d;
    height: 5px;
    border-radius: 16px;
  }
  .tab1 .MuiTabs-indicator {
    background-color: #f13838;
    height: 5px;
    border-radius: 16px;
  }

  .tab .MuiTab-textColorInherit.Mui-selected {
    color: #000 !important;
  }

  .tab1 .MuiTab-textColorInherit.Mui-selected {
    color: #000 !important;
  }
