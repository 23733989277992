.name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  color: #ffffff;
  text-align: left;
}
.username {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  text-align: left;
  color: #ffffff;
}

.editInfoMobile {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #6e6e6e;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.contentContainer {
  height: 300px;
  background-color: white;
  width: 100%;
  border-top-right-radius: 20%;
  z-index: 3;
  position: absolute;
  margin-top: 18.75em;
  border-bottom-left-radius: 20%;
}
.counts {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #09a9bb;
}
.countsType {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #09a9bb;
  opacity: 0.65;
}

.cardMobile {
  max-width: 10.188em;
  max-height: 3.938em;
  background: #fff2d3;
  border-radius: 10px;
  color: #f59604;
  line-height: 100px;
}

.cardActiveMobile {
  max-width: 10.188em;
  max-height: 3.938em;
  background: #f59604;
  border-radius: 10px;
  color: #ffffff;
  line-height: 100px;
}
.titleMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #3c4a6b;
  margin-top: 20px;
  text-align: center;
}
.infoTitleMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #3c4a6b;
  padding-bottom: 5px;
  text-align: left;
  margin-top: 30px;
}
.infoSubTitleMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 15px;
  color: #7b92a6;
  opacity: 0.78;
  text-align: left;
}
.descriptionMobile {
  box-sizing: border-box;
}
